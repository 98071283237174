@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
}

.accordion-section{
  background-color: #426B1F;
  color: #E8E8E8;
  padding-top:15px;
  padding: 15px 10px 10px 10px;
  max-width: 40vw;
  min-width: 235px;
  text-align: left;
  justify-content: space-between;
}

/* Rounded Top and Bottom */
#Breakfast{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

#SnacksDessert{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
/* -------------------------- */

.accordion-heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Newsreader';
  cursor: default;
}

.meal-title{
  font-size: 23px;
}

.action-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 0;
}

.play-button{
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button img{
  width: 13px;
  height: 19px;
  filter: brightness(0) invert(1);
}

.play-button:hover{
  cursor: pointer;
}


.regenerate.isLoading{
  animation: spin 2s linear infinite;
}

.toggle-icon {
  display: flex;
  height:32px;
  align-items: center;
  justify-content: center;
}

.toggle-icon img{
    width:20px;
    height:20px;
    filter: invert(1);
}

.unrotate45{
  /* -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg); 
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -sand-transform: rotate(45deg);
  transform: rotate(45deg); */
  -webkit-transition: .5s;
  transition: .5s;
}

.rotatecounterclockwise45{
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); 
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -sand-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: 1.0s;
  transition: 1.0s;
}

.accordion-panel {
  display: flex;
  font-family: 'Newsreader';
}

.accordion-panel.open {
  display: flex; /* Shown when this class is added */
  flex-direction: column;
}

.accordion-panel.closed{
  color: #426B1F;
}

.servings-title{
  cursor: default;
  font-size:18px;
  font-weight: 500;
}

.servings-input{
  background-color: #1c4500;
  border-radius: 7px;
  border-style: none;
  text-indent: 5px;
  color: white;
  font-size: 15px;
}

.servings-input:focus{
  outline: none;
  border: 1px solid white;
  caret-color: white;
}

.accordion-input{
  background-color: #1c4500;
  border-radius: 7px;
  border-style: none;
  width: stretch;
  height: 26px;
  margin-bottom:6px;
  text-indent: 5px;
  color: white;
  outline: none;
}

.accordion-input:focus {
  border: 1px solid white;
  caret-color: white;
}

.accordion-input:hover{
  border: 1px solid white;
}

.plan-meal-section {
  background-color: #426B1F;
  width: stretch;
  color: #E8E8E8;
  padding-top:15px;
  padding: 15px 10px 10px 10px;
  width: auto;
  text-align: left;
  cursor: pointer;
  justify-content: space-between;
}

.recipe-title {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-bottom: 5px;
  font-family: 'Newsreader';
  cursor: pointer;
}

.recipe-title:hover{
  text-decoration: underline;
}

.accordion-content {
  display: none; /* Hide by default */
  font-family: 'Newsreader';
}

.accordion-content.open {
  display: flex; /* Show when open */
  flex-direction: column;
}