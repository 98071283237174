.planner-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F6E4;
    position: fixed;
    width:100vw;
    height: 12vh;
    left:0;
    top:0;
    flex-wrap: nowrap;
    overflow: visible;
    z-index: 100;

}

.plan-title{
    margin-left:10vh;
}

.special-requests-header{
    flex-grow: 1;
    margin-left:8px;
    margin-right:18px;
}

header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F6E4;
    position: fixed;
    width:100vw;
    height: 40vh;
    top:0;
    flex-wrap: nowrap;
    overflow: visible;
    z-index: 100;
}

.title{
    display:flex;
    justify-content: center;
    align-items: center;
}

.mini-title{
    display: flex;
    font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
    font-size: 55px;
    color: #426B1F;
    font-weight: 500;
    margin-left: 3vw;
    margin-top: 6vh;
}


nav ul {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

nav ul li {
    margin-right: 3vw;
    cursor: pointer;
    white-space: nowrap;
    font-size: 16px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow:1;

}

.special-requests-bar{
    width:95%;
    font-size: 15px;
    padding: 10px;
    border-radius: 15px;
    background-color: #D9D9D9;
    border: 1px solid #426B1F;

}

/* .special-requests-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    padding-bottom: 100px;
} */

.special-requests-bar:focus {
    border: 3px solid #426B1F;
    border-radius: 15px;
    outline:none;
}


.generate-spin{
    height: auto;
    width: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}


.dead-space{
    display: flex;
    flex-direction: row;
    height: 20vh;
    width: 100vw;
}