
.nav-bar
{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 10vw;
    height: calc(100vh - 32vh);
    top:22vh;
    bottom:0vh;
    left:0vh;
    position: fixed;
    border-right: 1px solid #426B1F;
}

.icon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:2vh;
    margin-bottom: 2vh;
    margin-left:3vw;
    z-index: 100;
}

.icon{
    width: 40px;
    height: auto;
}

.icon-text{
    font-family: 'Newsreader';
    font-size: 12px;
    color:#717171;
    display: flex;
    flex-wrap: wrap;
    width:44px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.icon-container:hover,
.submitBtn:hover{
    filter: brightness(0.5);
    cursor: pointer;
}

/* .plus-minus-container{
    display: flex;
    flex-direction: column;
    width: 55px;
    cursor: pointer;
} */

.add-day-button, 
.remove-day-button{
    color: white; 
    width: 40px; 
    height: 40px; 
    border: 1px solid white; 
    border-radius: 12px; 
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #426B1F; 
    font-family: 'Newsreader';
    z-index: 10;
    
}



@media(max-width: 700px){

    /* .plus-minus-container{
        width:auto;
        margin: 0vw 1vh;
        flex-direction: row;
        cursor: pointer;
        z-index: 10;
    } */

    .nav-bar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        bottom: 0; /* position it at the bottom */
        top: auto; /* override the previous top value */
        height: 10vh;
        width: 100%; /* make it span the whole width */
        position: fixed;
        background-color: #F7F6E4; 
        z-index: 5;/* set the background color */
    }
    
    .nav-bar::before {
        content: "";
        display: block;
        width: 80vw; /* set the width of the border */
        border-top: 1px solid #426B1F; /* set the border */
        position: absolute;
        left: 50%;
        height:10vh;
        transform: translateX(-50%); /* center the border */
    }

    .calendar-section{
        width:100vw;
        margin-left: 0;
    }

    .add-day-button:hover, 
    .remove-day-button:hover{
        filter: brightness(0.6);
        cursor: pointer;
        
    }
        

    
}