
.day-container{
  margin: 3%;
  background-color: rgba(0, 100, 0, 0);
  background-color: #D9D9D9;
  padding-top: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px grey;
}

.day-title{
  padding-left:7%;
  padding-bottom:3%;
  font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
  font-size: 28px;
  font-weight: 700;
}
