.saved-header{
    display: flex;
    width: 100vw;
    justify-content: flex-start;
    align-items: center;
    background: #F7F6E4;
    position: fixed;
    width:100vw;
    height: 12vh;
    left:0;
    top:0;
    flex-wrap: nowrap;
    overflow: visible;
    z-index: 100;
}

.mini-title{
    z-index: 100;;
}

.saved-header-content{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: 3vw;
    justify-content: center;
    width: 100vw;
}

.save-current-plan{
    display: flex;
    width: 50vw;
    justify-content: center;
    align-items: center;
    background-color: #426B1F;
    color: white;
    font-size: 17px;
    font-weight: 500;
    margin-left: 5vw;
    margin-right: 3vw;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    border: none;
}