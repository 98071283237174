.landing-window{
    text-align: center;
    min-height: 90vh;
}

.hero-text{
    font-family: 'Newsreader';
    margin: 3vh 5vw;
    margin-top: 25vh;
    font-weight: 440;
    
}

h2{
    font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
    font-size: 60px;
    font-weight: 350;
}

.hero-sub-text{
    font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
    margin: 0 18vw;
    margin-bottom:18vh;
    font-size: 56px;
    font-weight: semi-bold;
}


.goto-planner-button {
    position: relative;
    background-color: #436b1f39;
    color: #426B1F;
    padding: 20px 10px;
    border: 3px solid #426B1F;
    border-radius: 35px;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5vh;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform: translateZ(0);
}

.goto-planner-button:hover {
    filter: brightness(0.8);
    transform: translateY(-6px);
}


.text{
    color: white;
}

.veg-scroll{
    margin-bottom: 5vh;
}

.veg-scroll{
    display: none;
}