.sign-in-extras{
    color: #426B1F;
    border: none;
    background-color: #F7F6E4;
    border-radius: 10px;
    /* display: flex; */
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size:12px;
    font-weight: 501;
    justify-content: center;
    align-items: center;
    padding:5px 10px;
  }

  .have-accountq-text{
    margin-top:10px;
    margin-bottom:8px;
  }