.reset-form{
    margin-top: 15vh;
    margin-left:5vw;
}

.code-enter{
    margin-top:5vh;
    margin-left:5vw;
}

.enter-new-passwords{
    margin-top:5vh;
    margin-left:5vw;
}