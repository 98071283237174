.contact-box{
    margin-top:20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 20px;
    background-color: #D9D9D9;
    border-radius: 15px;
    box-shadow: 0px 0px 5px grey;
    flex-wrap: wrap;
}