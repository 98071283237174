.form-wrapper {
  margin: 3%;
  background-color: rgba(0, 100, 0, 0);
  background-color: #D9D9D9;
  padding-top: 8px;
  border-radius: 18px;
  box-shadow: 0px 0px 5px grey;
  width: 56vw;
  }

  @media(max-width: 539px){
    .form-wrapper{
      width: 75vw;
    }
  }


.display-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  
.sign-up-title{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    margin-top:20vh;
    margin-bottom: 0;
    color:#426B1F;
}

.sign-up-title-2{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  margin-top:0vh;
  margin-bottom: 0;
  color:#426B1F;
}

.success-container{
  display: flex;
  flex-direction: row;
  gap: 10px; /* Space between form fields */
  padding: 18px;
  border-radius: 18px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow to the form */
  background-color: #426B1F;
  color:white;
}

.check-icon{
  width:18px;
  height: auto;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between form fields */
  padding: 18px;
  border-radius: 18px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow to the form */
  background-color: #426B1F; 
}

.loading-container{
  display: flex;
  justify-content: center;
  padding: 10px;
}

.hidden{
  display: none;
}

.card-title{
  font-size: 30px;
  margin:10px;
  margin-left:5%;
  font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
}

.panel-title{
  font-size: 23px;
  color: white;
  font-family: 'Newsreader';
}

.sign-up-form-input{
  background-color: #1c4500;
  border-radius: 7px;
  border-style: none;
  width: stretch;
  height: 31px;
  margin-bottom:6px;
  text-indent: 5px;
  color: white;
  outline: none;
}

.sign-up-form-input:focus,
.sign-up-form-input:hover {
  border: 1px solid white;
  caret-color: white;
}

.submit-button {
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  background-color: #F7F6E4;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;

  color:#426B1F;
}

.submit-button:hover {
  filter: brightness(70%);
}
.error-container{
  
  border-radius: 5px;
  padding:0px;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
  color:rgb(171, 30, 86);

}

.have-accountq-text{
  color: white;
  font-size: 18px;
  font-family:'Newsreader';
}


.sign-in{
  color: #426B1F;
  border: none;
  background-color: #F7F6E4;
  border-radius: 10px;
  font-size:12px;
  font-weight: 501;
  justify-content: center;
  align-items: center;
  padding:5px 10px;
  width: fit-content;
}

.sign-in:hover{
  filter: brightness(70%);
}

.explainer-bar-signup{
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: rgba(148, 168, 131, 0.5);
  filter:brightness(1);
  text-align: center;
}