@keyframes fadeAndScale {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

.subscribePopup {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 40vh;
    width: 70vw;
    transform: translate(-50%, -50%);
    background-color: #426B1F;
    padding: 20px;
    z-index: 1000;
    border-radius: 15px; /* Border radius added here */
    border: 8px solid #F7F6E4 ; /* Border added here */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); /* Box shadow added here */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subscribeButton{
    background-color: #F7F6E4;
    width:70%;
    height:30%;
    padding: 15px 40px;
    border-radius: 10px; /* Border radius added here */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
    color: #426B1F;
    margin-top: 5%;
}
  
.featuresList{
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #F7F6E4;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Newsreader', 'Fallback Font', serif;
}

.featuresList li {
    position: relative;
    padding-left: 40px; /* Creates space for the custom bullet */
    margin-bottom: 2%;/* Adjust spacing between list items as needed */
    
  }
  
.featuresList li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%; /* Adjust to vertically center the bullet */
    transform: translateY(-50%); /* Centers the bullet vertically */
    width: 20px; /* Width of the custom bullet */
    height: 20px; /* Height of the custom bullet */
    background-image: url('../../resources/checkMark.svg');
    background-size: cover; /* Ensures the image covers the area without distortion */
}

.xbutton {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    cursor: pointer;
}
  
.xbutton img{
    width:20px;
    height:20px;
    filter: invert(1);
}

.descript{
    color: #F7F6E4;
    font-size: 25px;
    text-align: center;
    font-family: 'Newsreader', 'Fallback Font', serif;
}
