@keyframes scrollBanner {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2048px 0; 
    }
  }
  
  .banner {
    position: relative;
    width: 100%;
    height: 200px; 
    background-image: url('../../../public/veg.jpg'); /* Adjust the path as needed */
    background-repeat: repeat-x; /* Repeat the image horizontally */
    animation: scrollBanner 80s linear infinite; /* Adjust the time as needed */
    background-size: contain;
    z-index: -1;
  }
  
  .banner::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust as needed */
    background: linear-gradient(to top, #F7F6E4, transparent);
}