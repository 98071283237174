

.main-section{
    display: flex;
    width:calc(100vw - 10vw);
    height: auto;
    margin-left: 10vw;
    justify-content: center;
}

@media(max-width: 700px){
    .main-section{
        width:100vw;
        margin-left: 0;
    }
}

.text-box-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.form{
    height:100%;
    width:100%;
}

/* HEADER WITH SPECIAL REQUESTS BAR */

header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F6E4;
    position: fixed;
    width:100vw;
    height: 40vh;
    top:0;
    flex-wrap: nowrap;
    overflow: visible;
    z-index: 100;
}

.title{
    display:flex;
    justify-content: center;
    align-items: center;
}

.mini-title{
    display: flex;
    font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
    font-size: 55px;
    color: #426B1F;
    font-weight: 500;
    margin-left: 3vw;
    margin-top: 6vh;
}


nav ul {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

nav ul li {
    margin-right: 3vw;
    cursor: pointer;
    white-space: nowrap;
    font-size: 16px;
}

.generate-spin{
    height: auto;
    width: 20px;
    animation: spin 2s linear infinite;
    filter: invert(100%);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}


.loading-svg{
    height: auto;
    width: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}



.dead-space{
    display: flex;
    flex-direction: row;
    height: 20vh;
    width: 100vw;
}