body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F6E4;

}


@font-face {
  font-family: 'Newsreader';
  src: url('./resources/Newsreader-VariableFont_opsz,wght.ttf') format('truetype-variations');
  font-weight: 5000;
}

@font-face {
  font-family: 'NothingYouCouldDo';
  src: url('./resources/NothingYouCouldDo-Regular.ttf') format('truetype-variations');
  font-weight: 5000;
}