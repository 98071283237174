.saved-section{
    width:calc(100vw - 10vw);
    height: auto;
    margin-left: 10vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.saved-plan{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2%;
    padding: 2%;
    background-color: #D9D9D9;
    border-radius: 15px;
    box-shadow: 0px 0px 5px grey;
    width:200px;
    height: auto;
    
}

.saved-plan-title{
    font-weight: 700;
    margin: 5px;
}

.saved-plan-recipe{
    font-family: 'Newsreader', serif;

}

.saved-recipes{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.saved-plan-icon{
    width:22px;
    height:auto;
    cursor: pointer;
    margin: 0 7px;
}


@media(max-width: 700px){
    .saved-section{
        width:100vw;
        height: auto;
        margin-left: 0;
    }
}
