
.hero-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F6E4;
    position: fixed;
    width:100vw;
    height: 12vh;
    top:0;
    left:0;
    flex-wrap: nowrap;
    overflow: visible;
    z-index: 100;
}

.title-wrapper{
    display:flex;
    justify-content: center;
    align-items: center;
}

.header-title-long{
    font-family: 'Newsreader', 'Fallback Font', serif;
    font-size: 50px;
    color: #426B1F;
    font-weight: 500;
    margin-left: 3vw;
    margin-top: 6vh;
}

.header-title-short,
.header-title-mini{
    display: none;
}


.beta-icon{
    height:35px;
    width:40px;
    color: #426B1F;
    margin-top: 1vh;

}



@media (max-width: 750px) {
    .header-title-long{
        display: none;
    }
    .header-title-short{
        display: flex;
        font-family: 'Newsreader', 'Fallback Font', serif;
        font-size: 40px;
        color: #426B1F;
        font-weight: 500;
        margin-left: 3vw;
        margin-top: 6vh;
    }
}

@media (max-width: 390px){

    .header-title-short{
        display:none;
    }

    .header-title-mini{
        display: flex;
        font-family: 'NothingYouCouldDo', 'Fallback Font', serif;
        font-size: 55px;
        color: #426B1F;
        font-weight: 500;
        margin-left: 3vw;
        margin-top: 6vh;
    }
}



nav ul {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

nav ul li {
    margin-right: 3vw;
    cursor: pointer;
    white-space: nowrap;
    /* font-family: 'Newsreader', 'Fallback Font', serif;
    font-weight: 400; */
    font-size: 16px;
}

a{
    text-decoration: none;
    color: black;
}

.sign-in-button{
    background-color: #426B1F;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    margin-right: 20%;
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap; */
    white-space: nowrap;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow:1;

}


.icon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    border-radius: 50%;
    width: 55px;
    height: 55px;
}
