.submitBtn{
    position: fixed;
    bottom:10%;
    left:50%;
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color:#7ab211;
    border: none;
    align-items:center;
    justify-content:center;
    font-weight:bold;
    box-shadow: 4px 4px 4px grey;
    z-index: 100;
}

.submitBtn:hover{
    cursor: pointer;
    filter:none;
    background-color: #426B1F;
}

@media( max-width: 1036px){
    .submitBtn{
        bottom: 15%;
        left: 73%;
    }
}


.generate-text{
    font-weight: 500;
    font-size: 15px;
    margin-top:3px;
    text-align: center;
    font-family: 'Newsreader', 'Fallback Font', serif;
    color: #ffffff;
}

.play-icon{
    height: auto;
    width: 22px;
}