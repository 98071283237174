

.calendar-section{
    display: flex;
    width:calc(100vw - 10vw);
    margin-left: 10vw;
    height: auto;
    justify-content: center;
}

.text-box-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.form{
    height:100%;
    width:100%;
}

.explainer-bar{
    /* border-top: 1px solid #426B1F;
    border-bottom: 1px solid #426B1F; */
    display: flex;
    justify-content: center;
    width:calc(100vw - 10vw);
    margin-left: 10vw;
    background-color: rgba(148, 168, 131, 0.5);
    filter:brightness(1);
    text-align: center;
}


.explainer-text{
    font-family: 'Newsreader', serif;
    font-size: 20px;
    color: #426B1F;
}

.demo-credit-bar{
    width:calc(100vw - 10vw);
    margin-left: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vh;
    background-color: none;
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.demo-credit-text{
    font-family: 'Newsreader', serif;
    font-size: 20px;
    color: rgb(152, 66, 101);
}

@media(max-width: 700px){
    .calendar-section,
    .explainer-bar,
    .demo-credit-bar{
        width:100vw;
        margin-left: 0;
    }
}